<template>
  <b-overlay
    :show="loading"
    spinner-variant="primary"
  >
    <b-card>
      <div class="">
        <b-row v-if="error_message">
          <b-col>
            <small
              class="text-danger d-block mb-1"
            >
              {{ error_message }}
            </small>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            cols="12"
            md="6"
          >
            <b-form-group
              label="Телефон *"
              label-for="phone"
            >
              <a-phone-input
                id="phone"
                v-model.trim="item.phone"
                :state="get(validation, 'phone[0]') ? false : null"
              />
              <small
                v-if="!!get(validation, 'phone[0]')"
                class="text-danger"
              >
                {{ get(validation, 'phone[0]') }}
              </small>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
          >
            <b-form-group
              label="Роль"
              label-for="role_id"
            >
              <v-select
                id="role_id"
                v-model.trim="item.role_id"
                placeholder="Выберите роль"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="roles"
                :reduce="val => val.id"
                :clearable="false"
                input-id="user-role"
                label="name"
                :class="{
                  'is-invalid': !!get(validation, 'role_id[0]')
                }"
              />
              <small
                v-if="!!get(validation, 'role_id[0]')"
                class="text-danger"
              >
                {{ get(validation, 'role_id[0]') }}
              </small>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row class="mt-1">
          <b-col>
            <b-button
              variant="primary"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              @click="createItem"
            >
              Пригласить
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BCard, BRow, BCol, BFormGroup, BFormInput, BButton, BOverlay, BFormTextarea, BCardHeader,
  BCardTitle, BTable, BFormCheckbox,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { get } from 'lodash'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import APhoneInput from '@/components/form/APhoneInput.vue'

import { getRoles } from '@/services/main-api/business/roles'
import { createInvite } from '@/services/main-api/business/invites'

export default {
  name: 'CreateRoleView',
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    BOverlay,
    vSelect,
    BFormTextarea,
    BCardHeader,
    BCardTitle,
    BTable,
    BFormCheckbox,
    APhoneInput,
  },
  data() {
    return {
      item: {
        phone: '',
        role_id: null,
      },
      validation: {},
      error_message: '',
      loading: false,
      roles: [],
    }
  },
  computed: {},
  mounted() {
    if (!this.$store.getters['workingMode/mode']) {
      this.$router.push({ name: 'dashboard' })
    }
    this.getData()
  },
  methods: {
    async getData() {
      this.loading = true
      await this.getRoles()
      this.loading = false
    },
    async getRoles() {
      try {
        const res = await getRoles({
          company: this.$store.getters['workingMode/company_id'],
          perPage: 1000,
          page: 1,
        })
        this.roles = res.data.data.filter(i => i.code !== 'super_admin')
      } catch (e) {
        this.roles = []
      }
    },
    async createItem() {
      this.clearValidation()
      if (!this.checkValidation()) {
        return
      }
      this.loading = true
      try {
        const res = await createInvite({
          phone: this.item.phone,
          role_id: this.item.role_id,
          company_id: this.$store.getters['workingMode/company_id'],
        })
        this.item.id = res.data.id
        this.$toast({
          component: ToastificationContent,
          props: {
            icon: 'BellIcon',
            title: 'Создано!',
            text: 'Приглашение было создано.',
            variant: 'success',
          },
        })
        this.clearForm()
        await this.$router.push({ name: 'invites' })
      } catch (e) {
        this.validation = get(e.response, 'data.errors')
        this.error_message = get(e.response, 'data.error', '')
        this.$refs.first_tab.activate()
      } finally {
        this.loading = false
      }
    },
    get,
    clearValidation() {
      this.validation = {}
      this.error_message = ''
    },
    checkValidation() {
      let hasError = false
      if (!this.item.phone) {
        this.validation.phone = ['Укажите телефон']
        hasError = true
      }
      if (!this.item.role_id) {
        this.validation.role_id = ['Выберите роль']
        hasError = true
      }
      return !hasError
    },
    clearForm() {
      this.validation = {}
      this.error_message = ''
      this.item.phone = ''
      this.item.role_id = null
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
