<template>
  <b-form-input
    id="name"
    ref="phone"
    v-model="phone"
    v-mask="'+7 (999) 999-99-99'"
    :placeholder="placeholder"
    :state="state"
    :plaintext="plaintext"
    :readonly="readonly"
  />
</template>

<script>
import { BFormInput } from 'bootstrap-vue'

export default {
  name: 'APhoneInput',
  components: {
    BFormInput,
  },
  inheritAttrs: false,
  props: {
    state: {
      type: Boolean,
      default: null,
    },
    placeholder: {
      type: String,
      default: '+7 (___) ___-__-__',
    },
    value: {
      type: String,
      default: '',
    },
    plaintext: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      phone: '',
    }
  },
  watch: {
    phone(newValue) {
      const phone = newValue.replace(/[^+\d]/g, '')
      this.$emit('input', phone)
    },
    value(newValue) {
      this.phone = newValue
    },
  },
  created() {
    if (this.value) {
      this.phone = this.value
    }
  },
}
</script>

<style scoped>

</style>
